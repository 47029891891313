import {
  get,
  post,
  put,
  del,
  getExel,
  putUserId,
  delUserId,
  postUserId,
  putXtoken,
  postXtoken,
  delXtoken,
  postBuId,
  postExcel,
  getById,
} from "./base_y";

//获取单个
export function getIsStaff(data) {
  return get("/v3/yun/member/isStaff" , data);
}
export function putSummit(memberId,isStaff,isSummit){
  return put(`/v3/yun/member/Staff/Summit?memberId=${memberId}&isStaff=${isStaff}&isSummit=${isSummit}`) 
}
// 注销账号  
export function DelCancellation(memberId,cancelChannel){
    return del(`/v3/yun/member/deleted?memberId=${memberId}&cancelChannel=${cancelChannel}`) 
  }